export enum IeltsFormIds {
  D2C_FORM = 'D2C Form',
  MOCK_FORM = 'mock_form',
  DEMO_PROG_FORM = 'Demo Prog Form',
  IELTS_DEMO_FORM = 'Ielts Demo Form',
  T2I_FORM = 'T2I Form',
  IELTS_DEMO_MC_FORM = 'Ielts Demo MC Form',
  CONTEXTUAL_FORM_V1 = 'IELTS Context Demo Form v1',
  ONBOARDING_FORM = 'IELTS Context Demo Form - Onboarding Purpose',
  LIY_ONBOARDING_FORM = 'LIY Onboarding Form',
  LIY_SIGN_UP_FORM = 'LIY SignUp Form', // this removes the first 3 details from the form
  MENA_IELTS_DEMO_FORM = 'MENA IELTS Demo Form',
  LIY_MENA = 'LIY_MENA',
  LIY_FREEMIUM_FORM = 'LIY Freemium Form',
}

export enum LeadSources {
  MARKETING = 'Marketing', //this is WEBFLOW PAGE
  WEB_DASHBOARD = 'Web Dashboard', //profile/ielts dashoard
  WEB_DAIGNOSTIC_TESTS = 'Web Diagnostic Test', //profile/ielts/mocktest MOCKTESTS DASHBOARD
  OTHER = 'Other', //default one
  ASYNC_DEMO_SCREEN = 'Async Demo Screen',
  RESCHEDULE_DASHBOARD = 'Reschedule Dashboard',
  TOFU_DISQUALIFIED_CALENDAR = 'Tofu Disqualified',
}
