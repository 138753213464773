import { FunctionComponent } from 'react';
import Select from 'react-select';

interface OptionType {
  label: string;
  value: string;
}

interface DropdownProps {
  meta?: any;
  label?: string;
  placeholder?: string;
  options?: OptionType[];
  isSearchable?: boolean;
  rest?: any;
  input?: any;
  isRequired?: boolean;
  disabled?: boolean;
  helperText?: string;
  isMulti?: boolean;
  onChange?: any;
  inputLayoutCss?: string;
  showError?: boolean;
}

const CustomDropdown: FunctionComponent<DropdownProps> = ({
  input,
  meta,
  label,
  placeholder,
  options,
  isSearchable,
  isRequired,
  disabled,
  helperText,
  isMulti,
  onChange,
  inputLayoutCss,
  showError = true,
}) => {
  return (
    <div className="relative">
      <div className={input.value !== '' ? 'is-filled' : ''}>
        <Select
          id={`field-${input.name}`}
          className={`font-normal  focus:border-red-600 focus:border w-full relative ${
            showError &&
            meta.error &&
            meta.touched &&
            ' border-red-600 bg-red-100'
          } ${inputLayoutCss}`}
          theme={(theme) => ({
            ...theme,
            borderRadius: 6,
            colors: {
              ...theme.colors,
              primary: '#000000',
              primary25: '#c0c0c0',
              primary50: '#696969',
            },
          })}
          styles={{
            control: (styles, state: any) => {
              return {
                ...styles,
                borderWidth: 0,
                background: 'white',
                paddingTop: '0.4rem',
                // paddingBottom: '1px',
                paddingLeft: '1rem',
                // paddingRight: '0.4rem',
                // border: state.isFocused && '1.5px',
                outline: 'none',
                color: '#000000',
              };
            },
            menu: (styles) => {
              return {
                ...styles,
                zIndex: 99999,
                overflowY: 'scroll',
              };
            },
            input: (styles) => {
              return {
                ...styles,
                transform: 'translate3d(-6px,9px,0)',
                height: 'auto',
                color: '#000000',
                textShadow: '0 0 0 #616161',
              };
            },
            placeholder: (styles) => {
              return {
                ...styles,
                color: '#000000',
                fontWeight: 'normal',
                fontSize: '15px',
                '@media (max-width:768px)': {
                  fontSize: '14px',
                },
              };
            },
            singleValue: (styles) => {
              return {
                ...styles,
                marginLeft: '0px',
                color: '#000000',
              };
            },
            multiValue: (styles) => {
              return {
                ...styles,
                marginTop: '18px',
                marginLeft: '-10px',
                marginRight: '20px',
                color: 'white !important',
                backgroundColor: '#5A304F',
                cursor: 'pointer',
              };
            },
            multiValueLabel: (styles) => ({
              ...styles,
              color: 'white',
            }),
            indicatorSeparator: (styles) => ({
              ...styles,
              display: 'none',
            }),
            valueContainer: (styles) => {
              return {
                ...styles,
                padding: '4px 0px',
              };
            },
          }}
          placeholder={placeholder}
          options={options}
          onFocus={input.onFocus}
          onBlur={input.onBlur}
          onChange={(v) => {
            if (isMulti) {
              if (v) {
                const valueArr = (v as OptionType[]).reduce(
                  (a: string[], c: OptionType) => {
                    a.push(c.value);
                    return a;
                  },
                  [],
                );
                input.onChange(valueArr);
              } else {
                input.onChange(null);
              }
            } else {
              input.onChange((v as OptionType).value);
              if (onChange) {
                onChange(v as OptionType);
              }
            }
          }}
          required={isRequired}
          //   openOuterUp={true}
          isSearchable={isSearchable}
          value={options?.filter((a) =>
            isMulti ? input.value.includes(a.value) : a.value === input.value,
          )}
          defaultValue={options?.find((a) => a.value === input.value)}
          isDisabled={disabled}
          isMulti={isMulti}
        />
      </div>
      {showError && meta.touched && meta.error && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      {helperText && (
        <p className="mt-2 text-sm text-lightgray-400 leading-tight pl-4">
          {helperText}
        </p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
          z-index: 10;
          pointer-events: none;
        }
        .css-kzbgha-menu,
        .css-1d96cs0-menu {
          top: auto;
          bottom: 100%;
        }
      `}</style>
    </div>
  );
};

export default CustomDropdown;
