import CustomDropdown from 'components/Common/InputsAndUtils/CustomDropDownV2';
import CustomInputSelect from 'components/Common/InputsAndUtils/CustomInputSelect';
import TextField from 'components/Common/InputsAndUtils/TextField';
import {
  FormVersions,
  IeltsEliteFormFields,
  IeltsEliteFormFieldsIds,
  ieltsEliteJsonV3,
  ieltsEliteJsonV4,
  ieltsEliteJsonV5,
  ieltsEliteJsonV6,
  ieltsEliteJsonV7,
  ieltsEliteJsonV8,
} from 'components/DemoForm/demoFormJson';
import { FormIdQuery } from 'components/LandingPagesComponents/Interface';
import { indianMobileRegex } from 'DataMapper/RegexConstants';
import { type ValidationErrors } from 'final-form';
import { Field, FormSpy } from 'react-final-form';
import { imageBasePath } from 'utils/imageUtils';

export const demoAliasQueryConstant = 'alias';

export const autonextDecision = (
  dublicateFormValues: any,
  formJson: any,
  currentFormStep: number,
  setCurrentFormStep: Function,
  isClickingBack: boolean,
) => {
  let isNormalAutoNextRequired = true;
  const startInd = indexCal(formJson, currentFormStep)[0];
  const endIndex = indexCal(formJson, currentFormStep)[1];
  for (let i = startInd; i < endIndex; i++) {
    if (
      formJson?.fields[i]?.default_visibility &&
      !(
        formJson?.fields[i]?.type === 'numberInput' ||
        formJson?.fields[i]?.type == 'textInput'
      )
    ) {
      if (dublicateFormValues[formJson?.fields[i]?.fieldValue]) {
        continue;
      } else {
        isNormalAutoNextRequired = false;
      }
    } else {
      isNormalAutoNextRequired = false;
      return;
    }
  }

  if (
    isNormalAutoNextRequired &&
    !isClickingBack &&
    currentFormStep !== formJson.page_distribution.length
  ) {
    setCurrentFormStep(currentFormStep + 1);
  }
};

export const forceAutonextDecision = (
  formJson: any,
  currentFormStep: number,
  formValues: any,
  setCurrentFormStep: Function,
  isClickingBack: boolean,
) => {
  let isForceAutoNextRequired = false;
  const startInd = indexCal(formJson, currentFormStep)[0];
  const endIndex = indexCal(formJson, currentFormStep)[1];

  for (let i = startInd; i < endIndex; i++) {
    if (formJson?.fields[i]?.default_visibility) {
      return;
    } else {
      const selectedField =
        formJson?.fields[i]?.visibility_dependency?.dependencies;
      let isThisFieldVisible = true;
      for (let j = 0; j < selectedField?.length; j++) {
        const questionId = selectedField[j]?.question_id;

        if (
          selectedField[j]?.value?.includes(
            formValues[getDependentQuestionFieldName(formJson, questionId)],
          ) ||
          selectedField[j]?.value.length == 0
        ) {
          continue;
        } else {
          isThisFieldVisible = false;
        }
      }
      if (!isThisFieldVisible) {
        isForceAutoNextRequired = true;
      } else {
        return;
      }
    }
  }

  if (isForceAutoNextRequired && !isClickingBack) {
    setCurrentFormStep(currentFormStep + 1);
  } else if (isForceAutoNextRequired && isClickingBack) {
    setCurrentFormStep((prev: any) => prev - 1);
  }
};

export const indexCal = (formJson: any, currentFormStep: number) => {
  const modifiedFormStepQuestionDistribution = [
    ...formJson?.page_distribution?.slice(0, currentFormStep - 1),
  ];
  const sumTillCurrentIndex = modifiedFormStepQuestionDistribution?.reduce(
    (partialSum, a) => partialSum + a,
    0,
  );

  const startIndex = currentFormStep > 1 ? sumTillCurrentIndex : 0;
  const endIndex =
    startIndex + formJson?.page_distribution[currentFormStep - 1];

  return [startIndex, endIndex];
};

export const nameExtraSpacetriming = (name: string) => {
  //replacing extra space between name if any
  return name?.replace(/\s+/g, ' ')?.trim();
};

export const getDependentQuestionFieldName: any = (
  formJson: any,
  questionId: any,
) => {
  for (let i = 0; i < formJson?.fields.length; i++) {
    if (formJson?.fields[i].question_id === questionId) {
      return formJson?.fields[i].fieldValue;
    }
  }
  return 'df';
};

type RenderFieldsType = {
  component_type: any;
  question_text: any;
  options: any;
  validation: any;
  fieldValue: any;
  label: any;
  default_visibility: any;
  visibility_dependency: any;
  values: any;
  numGridColumns: any;
  isMultiSelect: any;
  maxSelect: any;
  formJson: any;
  setIsClickingBack: any;
  delayForStepChange: any;
  setDuplicateFormValues: any;
  duplicateFormValues: any;
  isLoggedIn: any;
  classicForm?: boolean;
  errors: ValidationErrors;
  touched: any;
  modified: any;
  valid: boolean;
  form?: any;
};
export const RenderFields = ({
  component_type,
  question_text,
  options,
  validation,
  fieldValue,
  label,
  default_visibility,
  visibility_dependency,
  values,
  numGridColumns,
  isMultiSelect = false,
  maxSelect = 1,
  formJson,
  setIsClickingBack,
  delayForStepChange,
  setDuplicateFormValues,
  duplicateFormValues,
  isLoggedIn,
  classicForm,
  errors,
  touched,
  valid,
  modified,
  form,
}: RenderFieldsType) => {
  let isThisFieldVisible = true;
  for (let i = 0; i < visibility_dependency?.dependencies?.length; i++) {
    if (
      visibility_dependency?.dependencies[i]?.value?.includes(
        values[
          getDependentQuestionFieldName(
            formJson,
            visibility_dependency?.dependencies[i]?.question_id,
          )
        ],
      )
    ) {
      continue;
    } else {
      isThisFieldVisible = false;
      break;
    }
  }
  if (isThisFieldVisible || default_visibility)
    switch (component_type) {
      case 'single_select_tile': {
        return (
          <>
            <p
              className={`${
                classicForm
                  ? 'text-center text-[#3D495C] md:text-2xl'
                  : 'text-[#0A0926] md:text-[14px]'
              } font-bold mb-3`}
            >
              {question_text}
            </p>
            <div className={'mb-6'}>
              <Field
                name={fieldValue}
                label={label}
                component={CustomInputSelect}
                options={options}
                isMultiSelect={isMultiSelect}
                maxSelect={maxSelect}
                numGridColumns={numGridColumns ?? null}
                validate={(v: any) => {
                  if (formJson?.required.includes(fieldValue) && !v)
                    return 'This is a mandatory field';
                }}
                onClick={(e: any) => {
                  setIsClickingBack(false);
                  if (!isMultiSelect && formJson?.is_auto_progressive) {
                    delayForStepChange.current = window.setTimeout(() => {
                      setDuplicateFormValues({
                        ...duplicateFormValues,
                        [fieldValue]: e?.target?.value,
                      });
                    }, 200);
                  }
                }}
                classicForm={classicForm}
              />
            </div>
          </>
        );
      }
      case 'single_select_tile_v2': {
        return (
          <>
            <p
              className={`${
                classicForm
                  ? 'text-center text-[#3D495C] md:text-2xl'
                  : 'text-[#0A0926] md:text-[14px]'
              } font-bold mb-3`}
            >
              {question_text}
            </p>
            <div className={'mb-6'}>
              <Field
                name={fieldValue}
                label={label}
                component={CustomInputSelect}
                options={options}
                isMultiSelect={isMultiSelect}
                maxSelect={maxSelect}
                numGridColumns={numGridColumns ?? null}
                gridLayoutCss="!grid-cols-1 gap-y-4"
                validate={(v: any) => {
                  if (formJson?.required.includes(fieldValue) && !v)
                    return 'This is a mandatory field';
                }}
                onClick={(e: any) => {
                  setIsClickingBack(false);
                  if (!isMultiSelect && formJson?.is_auto_progressive) {
                    delayForStepChange.current = window.setTimeout(() => {
                      setDuplicateFormValues({
                        ...duplicateFormValues,
                        [fieldValue]: e?.target?.value,
                      });
                    }, 200);
                  }
                }}
                classicForm={classicForm}
              />
            </div>
          </>
        );
      }

      case 'numberInput': {
        return (
          <>
            <p
              className={`text-[#0A0926] text-[12px] md:text-[14px] font-bold `}
            >
              {question_text}
            </p>
            <div className="md:px-7 md:py-2 px-4 py-2 w-full md:w-4/5 md:mx-auto">
              <Field
                name={fieldValue}
                component={TextField}
                placeholder={label}
                label={'label'}
                type="number"
                validate={(v: any) => {
                  if (formJson?.required.includes(fieldValue) && !v)
                    return 'This is a mandatory field';
                  if (v && validation.pattern) {
                    const regex = new RegExp(validation?.pattern);
                    if (!regex.test(v)) return validation.errorMessage;
                  }
                }}
              />
            </div>
          </>
        );
      }
      case 'phoneWithCountry': {
        const countryFieldName = formJson.fields.find(
          (item: any) =>
            item.question_id === IeltsEliteFormFieldsIds.countryCode,
        ).fieldValue;

        const hasErrorPhone =
          errors &&
          errors[IeltsEliteFormFields.phone] &&
          !valid &&
          touched[IeltsEliteFormFields.phone];
        const hasErrorCountryCode =
          errors &&
          errors[IeltsEliteFormFields.countryCode] &&
          !valid &&
          touched[IeltsEliteFormFields.countryCode];
        const modifiedFields =
          modified[IeltsEliteFormFields.phone] ||
          modified[IeltsEliteFormFields.countryCode];
        const { fieldValue: phoneFieldName } = formJson.fields.find(
          (item: any) => item.question_id === IeltsEliteFormFieldsIds.phone,
        );
        if ((fieldValue === 'phone' && !isLoggedIn) || fieldValue !== 'phone')
          return (
            <>
              <div
                className={`w-full md:mx-auto flex border bg-white rounded-lg ${
                  hasErrorCountryCode || hasErrorPhone
                    ? 'border-red-600'
                    : 'border-[#DBDBDB]'
                }`}
              >
                <div className="min-w-[128px]">
                  <Field
                    name={countryFieldName}
                    component={CustomDropdown}
                    options={options}
                    isMultiSelect={isMultiSelect}
                    maxSelect={maxSelect}
                    numGridColumns={numGridColumns ?? null}
                    validate={(v: any) => {
                      if (formJson?.required.includes(fieldValue) && !v)
                        return 'This is a mandatory field';
                    }}
                    onClick={(e: any) => {
                      setIsClickingBack(false);
                      if (!isMultiSelect && formJson?.is_auto_progressive) {
                        delayForStepChange.current = window.setTimeout(() => {
                          setDuplicateFormValues({
                            ...duplicateFormValues,
                            [fieldValue]: e?.target?.value,
                          });
                        }, 200);
                      }
                    }}
                    classicForm={classicForm}
                    inputLayoutCss=""
                    showError={false}
                  />
                </div>
                <div className="border-r-[2px] my-2  "></div>
                <div className="flex-grow">
                  {' '}
                  <Field
                    name={phoneFieldName}
                    component={TextField}
                    placeholder={label}
                    label={label}
                    type="number"
                    validate={(v: any) => {
                      if (formJson?.required.includes(phoneFieldName) && !v)
                        return 'This is a mandatory field';
                      else if (!v)
                        return 'Please enter a valid phone number with correct length';
                      if (v && !v.trim()) return 'Please enter correct number';
                    }}
                    inputLayoutCss="!border-[0px] focus:!shadow-none"
                    showError={false}
                  />
                </div>
              </div>
              {(hasErrorCountryCode || hasErrorPhone) && (
                <p className="mt-1 text-xs text-red-600">
                  {errors[IeltsEliteFormFields.phone] ||
                    errors[IeltsEliteFormFields.countryCode]}
                </p>
              )}
            </>
          );
      }
      case 'textInput': {
        if ((fieldValue === 'phone' && !isLoggedIn) || fieldValue !== 'phone')
          return (
            <>
              <div>
                {question_text && (
                  <p
                    className={`${
                      classicForm
                        ? 'text-center text-[#3D495C] md:text-2xl'
                        : 'text-[#0A0926] md:text-[14px]'
                    } font-bold mb-3`}
                  >
                    {question_text}
                  </p>
                )}
                <div className="md:py-2  mb-6 w-full md:mx-auto">
                  <Field
                    name={fieldValue}
                    component={TextField}
                    placeholder={label}
                    label={label}
                    type="text"
                    validate={(v: any) => {
                      if (formJson?.required.includes(fieldValue) && !v)
                        return 'This is a mandatory field';
                      if (v && !v.trim()) return 'Please enter correct answer';

                      if (v && validation.pattern) {
                        const regex = new RegExp(validation?.pattern);
                        if (!regex.test(v)) return validation.errorMessage;
                      }
                    }}
                  />
                </div>
              </div>
            </>
          );
      }
    }
};

export const checkIfWeAreInFrame = (): boolean => {
  try {
    if (window) {
      if (window.parent.location === window.location) {
        return false;
      } else {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getFormByFormVersion = (
  version: string | string[] | undefined,
): any => {
  if (version === FormVersions.MENA_FORM) return ieltsEliteJsonV4;
  else if (version === FormVersions.ONBOARDING_QUESTION_ADD)
    return ieltsEliteJsonV5;
  return ieltsEliteJsonV3;
};

export const getLiyFormByFormId = (
  formId: string | string[] | undefined,
): any => {
  if (formId === FormIdQuery.LIY_MENA_FORM) return ieltsEliteJsonV8;
  else if (formId === FormIdQuery.LIY_SIGNUP_FORM) return ieltsEliteJsonV7;
  return ieltsEliteJsonV6;
};

export const getByImageByFormVersion = (
  version: string | string[] | undefined,
  isMobile: boolean,
): string => {
  if (version === FormVersions.ONBOARDING_QUESTION_ADD) {
    return isMobile
      ? `${imageBasePath}/avataran_assets/images/course/Mobile BG SVG.svg`
      : `${imageBasePath}/avataran_assets/images/course/BG svg.svg`;
  }
  return isMobile
    ? 'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/background_illustration_mobile_Unavvg4y6.svg'
    : 'https://ik.imagekit.io/onsnhxjshmp/LeapScholar/background_illustration__8CsIRTyJ.svg';
};

function getLastTenDigits(str: string): string {
  if (str.length < 10) {
    return str;
  }
  return str.slice(-10);
}

export function getPhoneNumber(number: string): string | null {
  const regex = new RegExp(indianMobileRegex);
  if (regex.test(number)) {
    return getLastTenDigits(number);
  }
  return null;
}
