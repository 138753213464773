import { ReactNode } from 'react';

export interface HeroBannerData {
  heading: ReactNode;
  subHeading: ReactNode;
  sellingPoints: {
    listImage: string;
    list: Array<string>;
  };
  heroImg: string;
  heroImgMob: string;
  heroImgBase?: ReactNode;
  ctaText: ReactNode;
  cta: () => void;
  ctaBelowText?: string;
  cornerBgGradientWeb?: ReactNode;
  cornerBgGradientMob?: ReactNode;
}

export enum FormIdQuery {
  LIY_SIGNUP_FORM = 'liy_signup_form',
  LIY_MENA_FORM = 'liy_mena_form',
}
